import React from "react";
import CotizacionesFiltros from "../../../components/AccordionFilter/Cotizaciones";
import CotizacionesTable from "../../../components/Tables/CotizacionesTable";
import { ReportePorSkuTable } from "../../../components/Tables/ReporteVentas/ReportePorSku";
import { useSelector } from 'react-redux'
import Button from "../../../components/Button";

const CONSTANT = {
  title: 'Cotizaciones',
  path: 'cotizaciones',
  create_btn: 'Crear cotización'
}


const Listado = () => {
  const { resumen_mes } = useSelector(({ cotizaciones }) => cotizaciones)

  return (
    <>
      <h3 className="mb-1 d-flex justify-content-between">
        {/* <p><b>{CONSTANT.title}</b> - Listado</p> */}
      </h3>

      <div className="d-flex gap-50 my-50">
        <Button isLink text={CONSTANT.create_btn} url={`/${CONSTANT.path}/create`} icon="Plus" />
      </div>

      <CotizacionesFiltros />

      <CotizacionesTable />

      <ReportePorSkuTable data={resumen_mes} />
    </>
  );
};

export default Listado;
