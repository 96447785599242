// Utils
import { Routes, Route, Navigate } from 'react-router-dom';


// Components
import PanelBody from '../layout/PanelBody';
import Toaster from '../components/Toaster';


// Views
import Home from '../views/Home';


// Routes

import TemplateRouter from './TemplateRouter';
import ProductosRouter from './ProductosRouter';
import CodigosDescuentosRouter from './CodigosDescuentosRouter';
import ProveedoresRouter from './ProveedoresRouter';
import MetodosPagoRouter from './MetodosPagoRouter';
import PuntosEntregaRouter from './PuntosEntregaRouter';
import UsuariosRouter from './UsuariosRouter';
import PermisosRouter from './PermisosRouter';
import RolesRouter from './RolesRouter';
import OfertasRouter from './OfertasRouter';
import EtiquetasRouter from './EtiquetasRouter';
import AdicionalesRouter from './AdicionalesRouter';
import RoleHasPermissionsRouter from './RoleHasPermissionsRouter';
import Construction from '../components/Construction';
import PedidosRouter from './PedidosRouter';
import AgendamientosRouter from './AgendamientosRouter';
import StockRouter from './StockRouter';
import CotizacionesRouter from './CotizacionesRouter';
import EventosRouter from './EventosRouter';
import BusquedaClientesRouter from './BusquedaClientesRouter';
import ClavesRouter from './ClavesRouter';
import GeneralesRouter from './GeneralesRouter';
import ErpRouter from './ErpRouter';
import { useSelector } from 'react-redux';
import Sorry from '../components/Sorry';
import HistorialRouter from './HistorialRouter';
import ReporteVentasRouter from './ReporteVentasRouter';
import GarantiasRouter from './GarantiasRouter';
import { EmpresasRouter } from './EmpresasRouter';
import { AutogestionRouter } from './AutogestionRouter';
import { ServiciosRouter } from './ServiciosRouter';
// import { DevolucionesRouter } from './Devoluciones/DevolucionesRouter';
// import { MotivosRouter } from './Devoluciones/MotivosRouter';
// import { BancosRouter } from './Devoluciones/BancosRouter';
import { CuentasCajaRouter } from './CuentasCajaRouter';
import { BodegasRouter } from './BodegasRouter';



const MainRouter = () => {
   const { user } = useSelector(state => state.auth)

   if (!Object.entries(user).length) return (<></>)

   return (
      <PanelBody>
         <Routes>
            <Route path='/home' element={<Home />} />

            {/* Child Router */}
            <Route path='/generales/*' element={<GeneralesRouter />} />

            {user.permisos['productos.listar'] &&
               <Route path='/productos/*' element={<ProductosRouter />} />
            }

            <Route path='/cuentas-caja/*' element={<CuentasCajaRouter />} />

            <Route path='/bodegas/*' element={<BodegasRouter />} />

            {/* <Route path='/devoluciones/*' element={<DevolucionesRouter />} />

            <Route path='/devoluciones/motivos/*' element={<MotivosRouter />} />

            <Route path='/devoluciones/bancos/*' element={<BancosRouter />} /> */}

            {/* <Route path='/devoluciones/estatus/*' element={<EstatusRouter />} /> */}

            {/* {user.permisos['productos.listar'] &&
               <Route path='/servicios/*' element={<ServiciosRouter />} />
            } */}

            <Route path='/reporte-ventas/*' element={<ReporteVentasRouter />} />

            {user.permisos['proveedores.listar'] &&
               <Route path='/proveedores/*' element={<ProveedoresRouter />} />
            }

            <Route path='/erp/*' element={<ErpRouter />} />

            <Route path='/template/*' element={<TemplateRouter />} />


            {user.permisos['atributos.listar'] &&
               <Route path='/claves/*' element={<ClavesRouter />} />
            }

            {user.permisos['pedidos.listar'] &&
               <Route path='/pedidos/*' element={<PedidosRouter />} />
            }

            {/* {user.permisos['roles.listar'] && */}
            <Route path='/roles/*' element={<RolesRouter />} />
            {/* } */}

            {user.permisos['ofertas.listar'] &&
               <Route path='/ofertas/*' element={<OfertasRouter />} />
            }

            {user.permisos['adicionales.listar'] &&
               <Route path='/adicionales/*' element={<AdicionalesRouter />} />
            }

            {user.permisos['etiquetas.listar'] &&
               <Route path='/etiquetas/*' element={<EtiquetasRouter />} />
            }

            {user.permisos['metodos-pago.listar'] &&
               <Route path='/metodos-pago/*' element={<MetodosPagoRouter />} />
            }

            {user.permisos['puntos-entrega.listar'] &&
               <Route path='/puntos-entrega/*' element={<PuntosEntregaRouter />} />
            }

            {user.permisos['usuarios.listar'] &&
               <Route path='/usuarios/*' element={<UsuariosRouter />} />
            }

            {user.permisos['garantias.listar'] &&
               <Route path='/garantias/*' element={<GarantiasRouter />} />
            }

            {/* {user.permisos['historial.listar'] &&
            } */}
            <Route path='/historial-cambios/*' element={<HistorialRouter />} />

            {/* {user.permisos['permisos.listar'] && */}
            <Route path='/permisos/*' element={<PermisosRouter />} />
            {/* } */}

            {user.permisos['codigos-descuento.listar'] &&
               <Route path='/codigos-descuento/*' element={<CodigosDescuentosRouter />} />
            }

            {user.permisos['agendamientos.listar'] &&
               <Route path='/agendamientos/*' element={<AgendamientosRouter />} />
            }

            {user.permisos['stock.listar'] &&
               <Route path='/stock/*' element={<StockRouter />} />
            }

            {user.permisos['eventos.listar'] &&
               <Route path='/eventos/*' element={<EventosRouter />} />
            }

            {/* {user.permisos['busqueda-clientes.listar'] && */}
            <Route path='/busqueda-clientes/*' element={<BusquedaClientesRouter />} />
            {/* } */}

            {user.permisos['role-has-permissions.listar'] &&
               <Route path='/role-has-permissions/*' element={<RoleHasPermissionsRouter />} />
            }

            {user.permisos['proveedores.listar'] &&
               <Route path='/proveedores/*' element={<ProveedoresRouter />} />
            }

            {user.permisos['cotizaciones.listar'] &&
               <Route path='/cotizaciones/*' element={<CotizacionesRouter />} />
            }

            {user.permisos['empresas.listar'] &&
               <Route path='/empresas/*' element={<EmpresasRouter />} />
            }

            <Route path='/autogestion/*' element={<AutogestionRouter />} />

            <Route path='/construccion/' element={<Construction />} />
            <Route path='/sorry' element={<Sorry />} />
            <Route path='*' element={<Navigate to='/home' replace />} />
         </Routes>

         <Toaster />
      </PanelBody>
   )
}



export default MainRouter;